import React, { useState, useEffect } from "react";
import {
  Button,
  Typography,
  Paper,
  Box,
} from "@mui/material";
import triviaData from "./trivia.json";

function App() {
  const [currentTrivia, setCurrentTrivia] = useState(null);
  const [usedIndices, setUsedIndices] = useState([]);

  useEffect(() => {
    getRandomTrivia();
  }, []);

  const getRandomTrivia = () => {
    let availableIndices = triviaData.map((_, index) => index).filter(
      (index) => !usedIndices.includes(index)
    );

    if (availableIndices.length === 0) {
      setUsedIndices([]);
      availableIndices = triviaData.map((_, index) => index);
    }

    const randomIndex = availableIndices[Math.floor(Math.random() * availableIndices.length)];
    setCurrentTrivia(triviaData[randomIndex]);
    setUsedIndices([...usedIndices, randomIndex]);
  };

  return (
    <Box
      sx={{
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        padding: 2,
        backgroundColor: "#f0f0f0",
      }}
    >
      <Paper
        elevation={3}
        sx={{
          width: "100%",
          maxWidth: 400,
          minHeight: 300,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          padding: 2,
        }}
      >
        <Typography variant="h4" gutterBottom align="center">
          Did you know?
        </Typography>
        <Typography variant="h6" align="center">
          {currentTrivia?.text}
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={getRandomTrivia}
          sx={{ marginTop: 2 }}
        >
          Next
        </Button>
      </Paper>
    </Box>
  );
}

export default App;